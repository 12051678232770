








































































































































































































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InvoicesTable from '@/components/InvoicesTable.vue'
import SendMailForm from '@/components/SendMailForm.vue'
import { paymentStatesList, invoiceTypes, paymentTypes } from '@/formHelpers'
import { ApiResponse, Pagination, Invoice, Sale } from '@/interfaces'
import { mapState } from 'vuex'
import { DatePicker } from 'element-ui'
import QuickNav from '@/components/QuickNav.vue'  

interface SendMailData {
  customText?: string
  chosenModel?: number | null
}

interface SortObject {
  order: string
  prop: string
}

interface Parameter {
  search?: string | null
  page?: string | null
}

interface DropdownAction {
  action: string
  item?: Invoice
  status?: string
}

export default Vue.extend({
  components: {
    InvoicesTable,
    Nav,
    SendMailForm,
    QuickNav,      
  },
  data() {
    return {
      invoices: [],
      filteredInvoices: [],
      selectedInvoices: [] as number[],
      search: '',
      page: '1',
      limit: 50,
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      busy: false,
      structureId: null as number | null,
      structures: [] as Record<string, string | null>[],
      saleId: null as number | null,
      sales: [] as Record<string, string | null>[],
      invoiceNumberFilter: null as string | null,
      invoiceTypes,
      invoiceTypeFilter: null as string | null,
      paymentStatesList,
      paymentStateFilter: null as string | null,
      sendMailData: {} as SendMailData,
      showSendDocumentModal: false,
      showPaymentCreationModal: false,
      paymentTypes,
      paymentType: null as string | null,
      paymentDate: new Date().toISOString().split('T')[0],
      paymentNote: null as string | null,
      invoicingPeriod: [],
      invoicingPeriodOptions: {
        shortcuts: [
          {
            text: 'Mois dernier',
            onClick(picker: DatePicker) {
              const start = new Date()
              const startMonth = start.getMonth() - 1
              start.setDate(1)
              start.setMonth(startMonth)
              const end = new Date(start.getFullYear(), startMonth + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Mois en cours',
            onClick(picker: DatePicker) {
              const start = new Date()
              start.setDate(1)
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      selectedInvoicesNumber: 0,
      selectedInvoicesTotal: '',
      selectedInvoicesRemainingTotal: '',
      hasParentInvoiceFilter: 'no',
      invoiceStates: [
        { value: 'proforma', label: 'Proforma' },
        { value: 'emise', label: 'Emise' },
        { value: 'comptabilisee', label: 'Comptabilisée' },
      ],
      invoiceStateFilter: null as string | null,
      invoiceTotalFilter: '',
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
    selectedTotal(): string {
      return `${this.selectedInvoicesNumber} facture(s) pour un total de ${this.selectedInvoicesTotal} et restant dû de ${this.selectedInvoicesRemainingTotal}`
    },
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client.structures
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
      this.addons = this.user.client?.addons ?? 0
    }
    if (this.$route.query.search && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.structure) {
      this.structureId = parseInt(this.$route.query.structure as string)
    }

    if (this.$route.query.sale) {
      this.saleId = parseInt(this.$route.query.sale as string)
    }

    if (this.$route.query.invoiceNumber) {
      this.invoiceNumberFilter = this.$route.query.invoiceNumber as string
    }

    if (this.$route.query.paymentState) {
      this.paymentStateFilter = this.$route.query.paymentState as string
    }

    if (this.$route.query.invoiceType) {
      this.invoiceTypeFilter = this.$route.query.invoiceType as string
    }

    if (this.$route.query.invoicingPeriod) {
      this.invoicingPeriod = this.$route.query.invoicingPeriod as []
    }

    if (this.$route.query.hasParentInvoice) {
      this.hasParentInvoiceFilter = this.$route.query.hasParentInvoice as string
    }

    if (this.$route.query.invoiceState) {
      this.invoiceStateFilter = this.$route.query.invoiceState as string
    }

    if (this.$route.query.invoiceTotal) {
      this.invoiceTotalFilter = this.$route.query.invoiceTotal as string
    }

    this.getInvoices()
    this.getSales()
  },
  methods: {
    updateRoute(resetPage = false) {
      if (resetPage) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        limit: this.limit,
        structure: this.structureId,
        sale: this.saleId,
        invoiceNumber: this.invoiceNumberFilter,
        paymentState: this.paymentStateFilter,
        invoiceType: this.invoiceTypeFilter,
        invoicingPeriod: this.invoicingPeriod,
        hasParentInvoice: this.hasParentInvoiceFilter,
        invoiceState: this.invoiceStateFilter,
        invoiceTotal: this.invoiceTotalFilter,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    sortChange(sort: SortObject) {
      this.order = null
      this.orderBy = null

      switch (sort.order) {
        case 'ascending':
          this.order = 'ASC'
          break

        case 'descending':
          this.order = 'DESC'
          break

        default:
          this.order = 'DESC'
          break
      }

      this.orderBy = sort.prop

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        limit: this.limit,
        structure: this.structureId,
        sale: this.saleId,
        invoiceNumber: this.invoiceNumberFilter,
        paymentState: this.paymentStateFilter,
        invoiceType: this.invoiceTypeFilter,
        invoicingPeriod: this.invoicingPeriod,
        hasParentInvoice: this.hasParentInvoiceFilter,
        invoiceState: this.invoiceStateFilter,
        invoiceTotal: this.invoiceTotalFilter,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getInvoices() {
      this.invoices = []
      this.filteredInvoices = []
      if (!this.busy) {
        this.busy = true

        const params = {} as Parameter
        if (this.search) {
          params.search = this.search
        } else {
          delete params.search
        }

        this.$router
          .push({
            query: Object.assign({}, this.$route.query, params),
          })
          .catch(() => {
            // This empty catch method is here to avoid the "Avoided redundant navigation" error
          })

        const loading = this.$loading({
          target: '#invoicesTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/invoices', {
            params: {
              search: this.search,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
              limit: this.limit,
              structure: this.structureId,
              sale: this.saleId,
              invoiceNumber: this.invoiceNumberFilter,
              paymentState: this.paymentStateFilter,
              invoiceType: this.invoiceTypeFilter,
              invoicingPeriod: this.invoicingPeriod,
              hasParentInvoice: this.hasParentInvoiceFilter,
              invoiceState: this.invoiceStateFilter,
              invoiceTotal: this.invoiceTotalFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.invoices = apiResponse.data.invoices
            this.filteredInvoices = this.invoices

            //if (!this.saleId && !this.paymentStateFilter) {
            if (apiResponse.data.pagination) {
              const pagination = apiResponse.data.pagination as Pagination

              this.activePage = pagination.current
              this.itemsTotal = pagination.totalCount
              this.numPages = pagination.pageCount
            } else {
              this.itemsTotal = 0
              this.numPages = 0
            }
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    getSales() {
      this.$api
        .get(`/sales`, {
          params: {
            type: 'simple',
            pagination: false,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.sales = apiResponse.data
        })
    },
    handleItemCommand(command: DropdownAction) {
      if (command.action == 'massDetach') {
        this.massDetachRequisitionItemFromInvoice()
      }
      if (command.action == 'massDelete') {
        this.massDeleteInvoice()
      }
    },
    updateSelectedInvoices(invoices: Invoice[]) {
      this.selectedInvoicesNumber = invoices.length
      this.selectedInvoices = []
      let total = 0
      let remainingTotal = 0
      invoices.forEach((invoice) => {
        if (invoice?.id) {
          this.selectedInvoices?.push(invoice.id)
          total += parseFloat(invoice.priceIncludingTaxes ?? '')
          remainingTotal += invoice.remainingPayment ?? 0
        }
      })
      this.selectedInvoicesTotal = this.formatCurrency(total)
      this.selectedInvoicesRemainingTotal = this.formatCurrency(remainingTotal)
    },
    formatCurrency(number: number | null): string {
      if (!number) {
        number = 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
    },
    openSendMailModal() {
      this.showSendDocumentModal = true
    },
    closeSendMailModal() {
      this.showSendDocumentModal = false
    },
    massSendEmailInvoice() {
      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      let success = false
      this.$api
        .post(`/invoices/mass-email-send`, {
          ids: this.selectedInvoices,
          customText: this.sendMailData.customText,
          chosenModel: this.sendMailData.chosenModel,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: apiResponse.message ?? 'Opération réalisée avec succès !',
          })
          ;(this.$refs.invoiceTable as InstanceType<typeof InvoicesTable>).clearSelected()
          success = true
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          if (success) {
            this.closeSendMailModal()
          }
          loading.close()
        })
    },
    openPaymentCreationModal() {
      this.showPaymentCreationModal = true
    },
    closePaymentCreationModal() {
      this.paymentType = null
      this.paymentNote = null
      this.showPaymentCreationModal = false
    },
    massPaymentCreation() {
      if (!this.busy) {
        this.busy = true

        let success = false
        this.$api
          .post(`/invoices/mass-payment-creation`, {
            ids: this.selectedInvoices,
            type: this.paymentType,
            note: this.paymentNote,
            date: this.paymentDate,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.$notify({
              type: 'success',
              title: 'Succès',
              message: apiResponse.message ?? 'Paiements créés avec succès.',
            })
            success = true
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            if (success) {
              this.closePaymentCreationModal()
              this.getInvoices()
            }
          })
      }
    },
    massDownloadInvoice() {
      if (!this.busy) {
        this.busy = true

        let success = false
        this.$api
          .post(`/invoices/mass-download-invoice`, {
            ids: this.selectedInvoices,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.success === true) {
              const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
              window.open(documentUrl, '_blank')
            }
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    massInvoiceRegroupFees() {
      if (!this.busy) {
        this.busy = true
        this.$confirm(
          'La fusion des frais est irréversible. Êtes-vous sûr de vouloir fusionner les frais de ces factures ?',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            let success = false
            this.$api
              .post(`/invoices/mass-invoice-regroup-fees`, {
                ids: this.selectedInvoices,
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                this.$notify({
                  type: 'success',
                  title: 'Succès',
                  message: apiResponse.message ?? 'Lignes de frais fusionnées avec succès.',
                })
                success = true
              })
              .catch((error) => {
                if (error.response) {
                  const apiResponse = error.response.data as ApiResponse
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      apiResponse.message ??
                      'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                  })
                }
              })
              .finally(() => {
                this.busy = false
                if (success) {
                  this.getInvoices()
                }
              })
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
            this.busy = false
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    massInvoiceGeneration() {
      if (!this.busy) {
        this.busy = true
        this.$confirm(
          'La génération de factures est irréversible. Êtes-vous sûr de vouloir générer ces factures ?',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            let success = false
            this.$api
              .post(`/invoices/mass-invoice-generation`, {
                ids: this.selectedInvoices,
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                this.$notify({
                  type: 'success',
                  title: 'Succès',
                  message: apiResponse.message ?? 'Factures générées avec succès.',
                })
                success = true
              })
              .catch((error) => {
                if (error.response) {
                  const apiResponse = error.response.data as ApiResponse
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      apiResponse.message ??
                      'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                  })
                }
              })
              .finally(() => {
                this.busy = false
                if (success) {
                  this.getInvoices()
                }
              })
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
            this.busy = false
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    exportPaymentDownload() {
      if (!this.busy) {
        this.busy = true
        this.$confirm(
          'Un fichier de virement sera créé uniquement avec les montants du reste à payer des factures de type décompte. Si vous souhaitez saisir le règlement sur ces factures, maintenez la sélection et utiliser la fonction de saisie de règlement en masse.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            let success = false
            this.$api
              .post(`/invoices/export-payment`, {
                ids: this.selectedInvoices,
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse
                success = true
                if (apiResponse.success === true) {
                  const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
                  window.open(documentUrl, '_blank')
                }
              })
              .catch((error) => {
                if (error.response) {
                  const apiResponse = error.response.data as ApiResponse
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      apiResponse.message ??
                      'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                  })
                }
              })
              .finally(() => {
                this.busy = false
              })
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
            this.busy = false
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    massDeleteInvoice() {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ces factures ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/invoices/mass-delete`, {
            data: {
              items: this.selectedInvoices,
            },
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
            ;(this.$refs.invoiceTable as InstanceType<typeof InvoicesTable>).clearSelected()
            this.getInvoices()
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    massDetachRequisitionItemFromInvoice() {
      this.$confirm(
        `Êtes-vous sûr(e) de vouloir détacher tous les lots de ces factures ? Attention, cette opération est irréversible.`,
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .put(`/invoices/mass-detach-items`, {
            ids: this.selectedInvoices,
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
            ;(this.$refs.invoiceTable as InstanceType<typeof InvoicesTable>).clearSelected()
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
  },
})
